import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-module',
  templateUrl: './task-module.component.html',
  styleUrls: ['./task-module.component.scss']
})
export class TaskModuleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
